import { Formik } from 'formik';
import _cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React, { Suspense, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Divider, Form, Grid, Header, Tab } from 'semantic-ui-react';

import { auditsAPI, multipartAPI } from '../../../../api';
import { Entity, QuestionType, UserRole } from '../../../../constants';
import getFieldStatus from '../../../../fieldLogic/audit';
import FieldStatus from '../../../../fieldLogic/fieldStatus';
import { useUserRole } from '../../../../hooks';
import { featureFlagsSelector } from '../../../../state/constants/selectors';
import CustomError from '../../../../utils/customError';
import Sentry from '../../../../utils/sentry';
import { LoadingPlaceholder, WaitPlaceholder } from '../../../common';
import GenericModal from '../../../common/GernericModal/GenericModal.component';
import { uuidType } from '../../../types';
import SurveyQuestionWrapper from '../../Surveys/SurveyDetails/SurveyQuestions/SurveyQuestionsEditor/SurveyQuestionWrapper/SurveyQuestionWrapper.component';
import AuditActions from '../AuditDetailsHeader/AuditActions/AuditActions.component';
import styles from './AuditDetails.module.scss';
import AuditDetailsAuditor from './AuditDetailsAuditor/AuditDetailsAuditor.component';
import AuditDetailsExpenses from './AuditDetailsExpenses/AuditDetailsExpenses.component';
import AuditDetailsFailLetter from './AuditDetailsFailLetter/AuditDetailsFailLetter.component';
import AuditDetailsKeyInfo from './AuditDetailsKeyInfo/AuditDetailsKeyInfo.component';
import AuditDetailsMatchings from './AuditDetailsMatchings/AuditDetailsMatchings.component';
import AuditDetailsQuery from './AuditDetailsQuery/AuditDetailsQuery.component';
import AuditDetailsReports from './AuditDetailsReports/AuditDetailsReports.component';
import AuditDetailsVisit from './AuditDetailsVisit/AuditDetailsVisit.component';
import { AuditDetailsSchema, handleAnswerTypeChange } from './helpers';

const scrollToRef = (ref, parentRef) => parentRef.current.scrollTo(0, ref.current.offsetTop - 50);

const DATA_CY = 'audit-details';

const AuditDetails = ({
  data,
  entityId,
  refetch,
  revisionId,
  singleColumn,
  submitFormRef,
}) => {
  const role = useUserRole();
  const alert = useAlert();
  const { automaticFailLetterNotification } = useSelector(featureFlagsSelector);

  const [auditActionType, setAuditActionType] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(data.status);
  const [currentAnswers, setCurrentAnswers] = useState(data.survey_answers);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let panes = [
    { menuItem: 'Matchings', component: AuditDetailsMatchings, ref: useRef(null) },
    { menuItem: 'Key Info', component: AuditDetailsKeyInfo, ref: useRef(null) },
    { menuItem: 'Audit', component: AuditDetailsVisit, ref: useRef(null) },
    { menuItem: 'Auditor', component: AuditDetailsAuditor, ref: useRef(null) },
    { menuItem: 'Expenses', component: AuditDetailsExpenses, ref: useRef(null) },
    { menuItem: 'Query', component: AuditDetailsQuery, ref: useRef(null) },
    { menuItem: 'Reports', component: AuditDetailsReports, ref: useRef(null) },
    { menuItem: 'Fail Letter', component: AuditDetailsFailLetter, ref: useRef(null) },
  ];

  if (!automaticFailLetterNotification) { // TODO: remove this when automaticFailLetterNotification feature flag is removed
    panes = panes.slice(0, panes.length - 1);
  }

  const showPanes = panesToShow => {
    if (role === UserRole.CLIENT) {
      return panesToShow.filter(pane => pane.menuItem !== 'Matchings');
    }
    return panesToShow;
  };

  const surveyVisibility = getFieldStatus(role, data.status, revisionId)('survey_answers');

  const openResetAndReassignModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);

  const { mutate: auditResetMutation } = useMutation(auditsAPI.reset, {
    onSuccess: () => {
      alert.success('Audit reset and reassigned!');
      refetch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error resetting or reassigning the audit: ${error.message}`);
    },
  });

  const handleResetAndReassign = ({ reassign, keepData }) => {
    auditResetMutation({ entityId, reassign, keepData });
  };

  const { mutate: surveyMutation, status: surveyMutationStatus } = useMutation(auditsAPI.amend, {
    onSuccess: response => {
      alert.success('Branching updated! Answers won\'t be saved until you save the audit!');
      setCurrentAnswers(response.answers);
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error updating audit: ${error.message}`);
    },
  });

  const { mutate: auditUpdate } = useMutation(multipartAPI.update, {
    onSuccess: () => {
      alert.success('Audit succesfully updated!');
      refetch();
    },
    onError: error => {
      Sentry.captureException(new CustomError(error));
      alert.error(`Error updating audit: ${error.message}`);
    },
  });

  const handleAuditUpdate = payload => {
    const formData = new FormData();
    const {
      newAnswersFiles,
      expenses_receipts_urls,
      expenses_receipts,
      travel_expenses_images_urls,
      travel_expenses_images,
      ...rest
    } = payload;

    Object.entries(rest).forEach(([key, value]) => {
      if (typeof value === 'object') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    Object.entries(newAnswersFiles).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          formData.append('answers_files', item, key);
        });
      } else if (value === null) {
        formData.append('answers_files', new Blob(), key);
      } else {
        formData.append('answers_files', value, key);
      }
    });

    const expensesReceipts = [];
    expenses_receipts.forEach(({ id, url, file, state }, index) => {
      const isNewFile = state === 'new';
      if (isNewFile) {
        const fileName = `new_expenses_receipts_${index}`;
        formData.append('expenses_receipts_files', file, fileName);
        expensesReceipts.push({ id: fileName, state });
      } else {
        expensesReceipts.push({ id, url, state });
      }
    });
    formData.append('expenses_receipts', JSON.stringify(expensesReceipts));

    const travelExpensesImages = [];
    travel_expenses_images.forEach(({ id, url, file, state }, index) => {
      const isNewFile = state === 'new';
      if (isNewFile) {
        const fileName = `new_travel_expenses_images_${index}`;
        formData.append('travel_expenses_files', file, fileName);
        travelExpensesImages.push({ id: fileName, state });
      } else {
        travelExpensesImages.push({ id, url, state });
      }
    });
    formData.append('travel_expenses_images', JSON.stringify(travelExpensesImages));

    auditUpdate({
      entity: Entity.AUDITS,
      entityId,
      payload: formData,
    });
  };

  const handleAmend = tempAnswers => {
    if (surveyMutationStatus !== 'loading') {
      surveyMutation({
        entityId,
        payload: { answers: tempAnswers },
      });
    }
  };

  const detailsRef = useRef(null);
  const executeScroll = index => scrollToRef(panes[index].ref, detailsRef);
  const renderLoader = () => <LoadingPlaceholder />;

  if (surveyMutationStatus === 'loading') {
    return <WaitPlaceholder />;
  }

  return (
    <Formik
      initialValues={{
        ...data,
        questions: data.revision_questions || [],
        answers: currentAnswers,
        answers_urls: data.survey_answers_urls,
        expenses_receipts_urls: data.expenses_receipts.map(({ url }) => url),
        travel_expenses_images_urls: data.travel_expenses_images.map(({ url }) => url),
        newAnswersFiles: {},
      }}
      validationSchema={AuditDetailsSchema}
      onSubmit={values => {
        handleAuditUpdate({
          ...values,
          survey_answers: values.answers,
          status: currentStatus,
          auditActionType,
        });
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, isValid }) => {
        const submit = () => {
          if (!isValid) {
            alert.error(
              <pre>
                {JSON.stringify(errors, null, 2)}
              </pre>,
            );
          } else {
            handleSubmit();
          }
        };

        const handleAnswerChange = async (question, newValue, imageIndex, imageValue) => {
          const answerIndex = values.answers?.findIndex(a => a.id === question.id);

          if (answerIndex !== -1) {
            let field = `answers.${answerIndex}.value`;
            let value = newValue;
            const handleAnswerChangeByTypeFn = handleAnswerTypeChange[question.type];

            if (handleAnswerChangeByTypeFn) {
              const {
                field: answerChangeField,
                value: answerChangeValue,
              } = await handleAnswerChangeByTypeFn(question, values, newValue, imageIndex, imageValue, answerIndex);
              field = answerChangeField;
              value = answerChangeValue;
            }

            setFieldValue(field, value);

            if (
              [
                QuestionType.RADIO,
                QuestionType.CHECKBOX,
                QuestionType.SELECT,
              ].includes(question.type)
            ) {
              const tempAnswers = _cloneDeep(values.answers);
              tempAnswers[answerIndex].value = newValue;
              handleAmend(tempAnswers);
            }
          } else {
            alert.info('This audit hasn\'t been submitted yet by the auditor!');
          }
        };

        const onClickResetAndReasign = ({ reassign, keepData }) => {
          handleResetAndReassign({ reassign, keepData });
          onCloseModal();
        };

        return (
          <Form>
            <Grid
              columns={singleColumn || surveyVisibility !== FieldStatus.HIDDEN ? 2 : 1}
              data-cy={DATA_CY}
            >
              {revisionId === null && (
                <Grid.Row className={styles.auditActionsRow}>
                  <AuditActions
                    auditStatus={data.status}
                    modalOpen={isModalOpen}
                    openResetAndReassignModal={openResetAndReassignModal}
                    refetch={refetch}
                    setAuditActionType={setAuditActionType}
                    setCurrentStatus={setCurrentStatus}
                    onSubmit={submit}
                  />
                </Grid.Row>
              )}

              {isModalOpen
                && (
                  <GenericModal
                    buttons={[
                      { label: 'Clear data', func: () => onClickResetAndReasign({ reassign: true, keepData: false }) },
                      { label: 'Keep data', func: () => onClickResetAndReasign({ reassign: true, keepData: true }) },
                      { label: 'Cancel', func: onCloseModal, isPrimary: true },
                    ]}
                    headerText="Do you want to keep this audit data, or clear it?"
                    open={isModalOpen}
                    onDismiss={onCloseModal}
                  />
                )}

              {surveyVisibility !== FieldStatus.HIDDEN ? (
                <Grid.Column className={styles.questionsColumn}>
                  <div className={styles.header}>
                    {'Survey Questions'}
                  </div>
                  <div className={styles.scrollable}>
                    {values.questions.map((question, index) => {
                      let answer = values.answers.find(a => a.id === question.id)?.value;
                      let formRecognitionValues;
                      if (answer && question.type === QuestionType.IMAGE) {
                        formRecognitionValues = data?.form_recognition_values?.[question.id]
                          || null;
                        answer = values.answers_urls?.find(url => url.id === question.id)?.urls;
                      }

                      return (
                        <SurveyQuestionWrapper
                          key={index}
                          auditScreen
                          answer={answer}
                          data={question}
                          formRecognitionValues={formRecognitionValues}
                          index={index}
                          refetch={refetch}
                          status={data.status}
                          surveyVisibility={surveyVisibility}
                          onChange={(
                            newValue,
                            imageIndex,
                            imageValue,
                          ) => handleAnswerChange(question, newValue, imageIndex, imageValue)}
                        />
                      );
                    })}
                  </div>
                </Grid.Column>
              ) : null}

              <Grid.Column className={styles.detailsColumn}>
                <div className={styles.header}>
                  {'Survey Details'}
                </div>
                {submitFormRef ? (
                  <button
                    ref={submitFormRef}
                    hidden
                    type="submit"
                    onClick={submit}
                  />
                ) : null}
                <Tab
                  data-cy={`${DATA_CY}-tabs`}
                  menu={{ secondary: true, pointing: true }}
                  panes={showPanes(panes)}
                  onTabChange={(_e, dataTab) => {
                    executeScroll(dataTab.activeIndex);
                  }}
                />
                <div ref={detailsRef} className={styles.scrollable}>
                  <Suspense fallback={renderLoader()}>
                    {showPanes(panes).map(({ component: Panel, ref }, index) => (
                      <div key={index}>
                        <div ref={ref} />
                        <Divider horizontal section>
                          <Header as="h4">
                            {showPanes(panes)[index].menuItem}
                          </Header>
                        </Divider>
                        <Panel
                          labelStyle={styles.labelStyle}
                          revisionId={revisionId}
                          setCurrentStatus={setCurrentStatus}
                          valueStyle={styles.valueStyle}
                        />
                      </div>
                    ))}
                  </Suspense>
                </div>
              </Grid.Column>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

AuditDetails.defaultProps = {
  refetch: null,
  revisionId: null,
  singleColumn: false,
  submitFormRef: null,
};

AuditDetails.propTypes = {
  data: PropTypes.object.isRequired, // TODO: auditType.isRequired,
  entityId: uuidType.isRequired,
  refetch: PropTypes.func,
  revisionId: uuidType,
  singleColumn: PropTypes.bool,
  submitFormRef: PropTypes.object,
};

export default AuditDetails;
