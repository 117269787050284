import { Auth } from 'aws-amplify';
import classnames from 'classnames';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Divider, Dropdown } from 'semantic-ui-react';

import isOnline from '../../../utils/connection';
import Sentry from '../../../utils/sentry';
import InstallPWA from '../../common/InstallPWA/InstallPWA.component';
import { auditorMobileTabs } from './helpers';
import styles from './NavbarMobile.module.scss';

const DATA_CY = 'navbar';

const trigger = (
  <div className={styles.tabMobile}>
    <i className={classnames(styles.tabIcon, 'icon-dots')} />
    <div className={styles.tabLabel}>
      {'More'}
    </div>
  </div>
);

const isActive = (path, location) => location.pathname.startsWith(path);

const NavbarMobile = () => {
  const location = useLocation();
  const { push } = useHistory();

  const signOut = async () => {
    Sentry.setUser({});
    await Auth.signOut();
    push('/login');
  };

  return (
    <div className={styles.rootMobile} data-cy={DATA_CY}>
      <div className={styles.navbarMobile}>
        {auditorMobileTabs(isOnline()).map(({ name, path, icon }, index) => (
          <Link
            key={index}
            data-cy={`${DATA_CY}-menu-item-${name.toLowerCase().replace(/ /gi, '-')}`}
            to={path}
          >
            <div
              className={classnames(
                styles.tabMobile,
                { [styles.activeTabMobile]: isActive(path, location) },
              )}
            >
              <i className={classnames(styles.tabIcon, `${icon}`)} />
              <div className={styles.tabLabel}>
                {name}
              </div>
            </div>
          </Link>
        ))}
        <InstallPWA />

        <Dropdown trigger={trigger}>
          <Dropdown.Menu className={styles.dropdownBg}>
            <div className={styles.dropdownContent}>
              <h2 className={styles.dropdownHeader}>
                {'Information & Settings'}
              </h2>
              <Dropdown.Item>
                <Link
                  data-cy={`${DATA_CY}-menu-item-docs`}
                  to={'/audits?status=approved,approving_query,submitted,client_query'}
                >
                  <div
                    className={styles.tabDropdown}
                  >
                    <i className={classnames(styles.tabIcon, 'icon-orders')} />
                    <div className={styles.tabLabel}>
                      {'Finished audits'}
                    </div>
                  </div>
                </Link>
              </Dropdown.Item>
              <Divider className={styles.dividerMobile} />
              <Dropdown.Item>
                <Link
                  data-cy={`${DATA_CY}-menu-item-docs`}
                  to={'/audits/map'}
                >
                  <div
                    className={styles.tabDropdown}
                  >
                    <i className={classnames(styles.tabIcon, 'icon-map')} />
                    <div className={styles.tabLabel}>
                      {'Audit\'s map'}
                    </div>
                  </div>
                </Link>
              </Dropdown.Item>
              <Divider className={styles.dividerMobile} />
              <Dropdown.Item>
                <Link
                  data-cy={`${DATA_CY}-menu-item-docs`}
                  to={'/account'}
                >
                  <div
                    className={styles.tabDropdown}
                  >
                    <i className={classnames(styles.tabIcon, 'icon-user')} />
                    <div className={styles.tabLabel}>
                      {'Account'}
                    </div>
                  </div>
                </Link>
              </Dropdown.Item>
              <Divider className={styles.dividerMobile} />
              <Dropdown.Item>
                <div
                  className={styles.tabDropdown}
                  data-cy={`${DATA_CY}-menu-item-log-out`}
                  onClick={signOut}
                >
                  <i className={classnames(styles.tabIcon, 'icon-logout')} />
                  <div className={styles.tabLabel}>
                    {'Log Out'}
                  </div>
                </div>
              </Dropdown.Item>
              <Divider className={styles.dividerMobile} />
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default NavbarMobile;
