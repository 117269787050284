import { StatusCodes } from 'http-status-codes';

import axios from './axiosInstance';
import { getBackendConfig } from './configAPI';
import {
  getAuthorization,
  handlingResponse,
  logError,
} from './utils';

export const create = async ({ entity, payload }) => {
  const { url } = await getBackendConfig();

  return axios.post(
    `${url}/api/v2/${entity}`,
    payload,
    {
      headers: {
        Authorization: getAuthorization(),
        'Content-Type': 'multipart/form-data',
      },
    },
  )
    .then(handlingResponse([StatusCodes.OK], `Error creating new ${entity}`))
    .catch(logError);
};
