import _isNumber from 'lodash/isNumber';
import _max from 'lodash/max';
import _sum from 'lodash/sum';

import { QuestionType } from '../../../../constants';

export const questionInitialValues = type => {
  const question = {
    type,
    title: '',
    help: '',
    auditor_information: '',
    approving_information: '',
    required: true,
    category: null,
    config: {},
  };

  const currencyExtendedQuestion = {
    ...question,
    config: {
      suffix: '',
    },
  };

  const textExtendedQuestion = {
    ...question,
    config: {
      defaultValue: type === QuestionType.NUMBER ? 0 : '',
      prefix: '',
      rows: 1,
      suffix: '',
      regexp: null,
      regexpHelp: null,
    },
  };

  const selectorExtendedQuestion = {
    ...question,
    config: {
      options: [
        { selected: false, label: 'Yes', value: 'Yes' },
        { selected: false, label: 'No', value: 'No' },
      ],
    },
  };

  const extendedQuestion = {
    [QuestionType.CHECKBOX]: () => selectorExtendedQuestion,
    [QuestionType.CURRENCY]: () => currencyExtendedQuestion,
    [QuestionType.NUMBER]: () => textExtendedQuestion,
    [QuestionType.RADIO]: () => selectorExtendedQuestion,
    [QuestionType.SELECT]: () => selectorExtendedQuestion,
    [QuestionType.TEXT]: () => textExtendedQuestion,
    [QuestionType.TEXTAREA]: () => textExtendedQuestion,
    default: () => question,
  };

  return (extendedQuestion[type] || extendedQuestion.default)();
};

export const validateQuestionValue = schemas => value => {
  const validationSchemas = Array.isArray(schemas) ? schemas : [schemas];

  let errorMessage;
  try {
    validationSchemas.forEach(schema => {
      schema.validateSync(value);
    });
  } catch (error) {
    errorMessage = error.message;
  }

  return errorMessage;
};

export const isScoreQuestion = question => {
  const { type, config: { options } } = question;

  if (![QuestionType.CHECKBOX, QuestionType.RADIO, QuestionType.SELECT].includes(type)) return false;

  return options.find(option => _isNumber(option.score)) !== undefined;
};

export const calculateQuestionMaxScore = question => {
  if (!isScoreQuestion(question)) return null;

  const { type, config: { options } } = question;
  const scores = options.map(o => o.score);

  const calculate = {
    [QuestionType.CHECKBOX]: () => _sum(scores),
    [QuestionType.RADIO]: () => _max(scores),
    [QuestionType.SELECT]: () => _max(scores),
  };

  return calculate[type]();
};

export const calculateQuestionCurrentScore = (question, answer) => {
  if (!isScoreQuestion(question) || answer === null) return null;

  const { type, config: { options } } = question;

  const calculate = {
    [QuestionType.CHECKBOX]: () => _sum(options.filter(o => answer.includes(o.value)).map(v => v.score)),
    [QuestionType.RADIO]: () => options.find(o => o.value === answer)?.score,
    [QuestionType.SELECT]: () => options.find(o => o.value === answer)?.score,
  };

  return calculate[type]() || 0;
};
