import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { fetchConfigCat } from '../../../state/constants/actionCreators';
import { Register } from '../../layout';
import RegisterAuditorFormWrapper from './RegisterAuditorFormWrapper/RegisterAuditorFormWrapper.component';

const RegisterAuditor = () => {
  const dispatch = useDispatch();

  const fetchFeatureFlagsCallback = useCallback(
    () => dispatch(fetchConfigCat()),
    [dispatch],
  );

  useEffect(() => {
    fetchFeatureFlagsCallback();
  }, [fetchFeatureFlagsCallback]);

  return (
    <Register
      form={RegisterAuditorFormWrapper}
      title="Create new account"
    />
  );
};
export default RegisterAuditor;
