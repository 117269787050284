import classnames from 'classnames';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';

import { multipartAPI, multipartAPIV2 } from '../../../../api';
import { Entity } from '../../../../constants';
import { featureFlagsSelector } from '../../../../state/constants/selectors';
import { WaitPlaceholder } from '../../../common';
import RegisterAuditorCongrats from '../RegisterAuditorCongrats/RegisterAuditorCongrats.component';
import RegisterAuditorError from '../RegisterAuditorError/RegisterAuditorError.component';
import RegisterAuditorFormEight from '../RegisterAuditorFormSteps/RegisterAuditorFormStepEight/RegisterAuditorFormStepEight.component';
import RegisterAuditorFormEleven from '../RegisterAuditorFormSteps/RegisterAuditorFormStepEleven/RegisterAuditorFormEleven.component';
import RegisterAuditorFormFive from '../RegisterAuditorFormSteps/RegisterAuditorFormStepFive/RegisterAuditorFormStepFive.component';
import RegisterAuditorFormFour from '../RegisterAuditorFormSteps/RegisterAuditorFormStepFour/RegisterAuditorFormStepFour.component';
import RegisterAuditorFormNine from '../RegisterAuditorFormSteps/RegisterAuditorFormStepNine/RegisterAuditorFormStepNine.component';
import RegisterAuditorFormOne from '../RegisterAuditorFormSteps/RegisterAuditorFormStepOne/RegisterAuditorFormStepOne.component';
import RegisterAuditorFormSeven from '../RegisterAuditorFormSteps/RegisterAuditorFormStepSeven/RegisterAuditorFormStepSeven.component';
import RegisterAuditorFormSevenExtra from '../RegisterAuditorFormSteps/RegisterAuditorFormStepSevenExtra/RegisterAuditorFormStepSevenExtra.component';
import RegisterAuditorFormSix from '../RegisterAuditorFormSteps/RegisterAuditorFormStepSix/RegisterAuditorFormStepSix.component';
import RegisterAuditorFormTen from '../RegisterAuditorFormSteps/RegisterAuditorFormStepTen/RegisterAuditorFormStepTen.component';
import RegisterAuditorFormThirteen from '../RegisterAuditorFormSteps/RegisterAuditorFormStepThirteen/RegisterAuditorFormThirteen.component';
import RegisterAuditorFormThree from '../RegisterAuditorFormSteps/RegisterAuditorFormStepThree/RegisterAuditorFormStepThree.component';
import RegisterAuditorFormTwelve from '../RegisterAuditorFormSteps/RegisterAuditorFormStepTwelve/RegisterAuditorFormTwelve.component';
import RegisterAuditorFormTwo from '../RegisterAuditorFormSteps/RegisterAuditorFormStepTwo/RegisterAuditorFormStepTwo.component';
import { registerValidationSchema, registerValidationSchemaWithoutAuditorApplicationUpgrade } from './helpers';
import styles from './RegisterAuditorFormWrapper.module.scss';

const DATA_CY = 'register-form';

const RegisterAuditorFormWrapper = () => {
  const [registerState, setRegisterState] = useState('progress');
  const [stepNumber, setStepNumber] = useState(0);
  const { auditorApplicationUpgrade } = useSelector(featureFlagsSelector);

  const steps = [
    RegisterAuditorFormOne,
    RegisterAuditorFormTwo,
    RegisterAuditorFormThree,
    RegisterAuditorFormFour,
    RegisterAuditorFormFive,
    RegisterAuditorFormSix,
    RegisterAuditorFormSeven,
    RegisterAuditorFormSevenExtra,
    RegisterAuditorFormEight,
    RegisterAuditorFormNine,
    RegisterAuditorFormTen,
    RegisterAuditorFormEleven,
    RegisterAuditorFormTwelve,
    RegisterAuditorFormThirteen,
  ];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const currentValidationSchema = () => {
    if (auditorApplicationUpgrade) {
      return registerValidationSchema[stepNumber];
    }
    return registerValidationSchemaWithoutAuditorApplicationUpgrade[stepNumber];
  };

  const previous = values => {
    if (stepNumber === 8 && (values.nationality === 'GB' || values.nationality === 'IE')) {
      setStepNumber(Math.max(stepNumber - 2, 0));
    } else {
      setStepNumber(Math.max(stepNumber - 1, 0));
    }
  };

  const CurrentStep = steps[stepNumber];

  const [messageFromApi, setMessageFromApi] = useState('');

  const { mutate: registerAuditorMutate } = useMutation(auditorApplicationUpgrade
    ? multipartAPIV2.create : multipartAPI.create, {
    onSuccess: () => setRegisterState('success'),
    onError: error => {
      setRegisterState('error');
      setMessageFromApi(error.message);
    },
  });

  const handleStepSubmit = (values, formikBag) => {
    if (isLastStep) {
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      setRegisterState('loading');

      registerAuditorMutate({
        entity: Entity.AUDITORS,
        payload: formData,
      });
      formikBag.resetForm({});
    } else if (stepNumber === 6 && (values.nationality === 'GB' || values.nationality === 'IE')) {
      setStepNumber(Math.min(stepNumber + 2, totalSteps - 2));
    } else {
      setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    }
  };

  if (registerState === 'error') {
    return (
      <RegisterAuditorError
        messageFromApi={messageFromApi}
        setRegisterState={setRegisterState}
        setStepNumber={setStepNumber}
      />);
  }

  if (registerState === 'success') {
    return <RegisterAuditorCongrats />;
  }

  if (registerState === 'loading') {
    return <WaitPlaceholder />;
  }

  return (
    <Formik
      initialValues={{}}
      validationSchema={currentValidationSchema}
      onSubmit={(values, formikBag) => handleStepSubmit(values, formikBag)}
    >
      {({ handleSubmit, values }) => (
        <>
          <div className={classnames({ [styles.arrowButtons]: stepNumber > 0, [styles.none]: !stepNumber > 0 })}>
            <button
              className={styles.back}
              data-cy={'back-button'}
              disabled={stepNumber === 0}
              type="button"
              onClick={() => previous(values)}
            />
            <button
              className={styles.next}
              data-cy={'next-button'}
              disabled={isLastStep}
              type="submit"
              onClick={handleSubmit}
            />
          </div>
          <Form noValidate className={styles.root} data-cy={`${DATA_CY}-${stepNumber}`}>
            <CurrentStep />
            <div className={styles.buttonFooter}>

              <div>
                <Button
                  primary
                  data-cy={`${DATA_CY}-submit-button`}
                  size={!stepNumber > 0 ? 'big' : 'small'}
                  type="submit"
                  onClick={handleSubmit}
                >
                  {!stepNumber > 0 ? <>
                    {'Continue Registration'}
                    <span className={styles.continueIcon}></span>
                  </> : <>
                    {'OK'}
                    <i className={styles.iconAccept}></i>
                  </>}
                </Button>
                {stepNumber === 0 ? (
                  <div className={styles.footer}>
                    <span>
                      Do you have questions or need help?
                    </span>
                    <a
                      className={styles.contactUsLink}
                      href="http://www.servelegal.co.uk/get-in-touch/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Contact Us
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default RegisterAuditorFormWrapper;
